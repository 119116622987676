div {
  box-sizing: border-box;
}

img {
  object-fit: cover;
}

* {
  font-family: 'Roboto', sans-serif;
  text-underline-offset: 3px;
}

html,
body,
#root {
  height: 100%;
  background-color: black;
}

html {
  background-color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.link {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
}

.link:hover {
  border-bottom: 1px solid #00000000;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
  width: 0px;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 1px;
  background-color: white;
}

#enable-scroll::-webkit-scrollbar-track {
  width: 0px;
  background-color: #ffffff40;
  box-shadow: inset 0px 0px 0px 5px rgb(0, 0, 0);
}

#enable-scroll::-webkit-scrollbar {
  height: 11px;
  width: 1px;
}

#enable-scroll::-webkit-scrollbar-thumb {
  width: 0px;
  height: 11px;
  box-shadow: inset 0px 5px 0px 0px black, inset 0px -5px 0px 0px black;
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
}
